.row {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.number {
  aspect-ratio: 1;
  max-width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  width: 100%;
  // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
