.container {
  min-height: calc(100vh - 76px);
  margin-top: 64px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1050px) {
    margin-top: 24px;
    text-align: center;
  }
}

.content_container {
  max-width: 1050px;
  width: 90vw;
}

.title {
  max-width: 400px;
}

.left_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number {
  aspect-ratio: 1;
  max-width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
}

.guide_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
  padding: 32px 0px;
  margin-top: 64px;
  margin-bottom: 24px;
  @media screen and (max-width: 1050px) {
    margin-top: 24px;
  }
}

.guide_bullet_point {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.right_container {
  display: flex;
  position: relative;
  width: 100%;
  height: 600px;
  @media screen and (max-width: 1050px) {
    height: 400px;
  }
}

.top_container {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  width: 100%;
  margin-bottom: 64px;
}

.tagContainer {
  padding: 12px 32px;
  border-radius: 12px;
  background-color: var(--primary);
  width: fit-content;
  max-width: 90vw;
  @media screen and (max-width: 1050px) {
    padding: 8px 24px;
  }
}

.bottom_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 64px;
}

.tag_typography {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  @media screen and (max-width: 1050px) {
    font-size: 14px;
  }
}

.tagsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media screen and (max-width: 1050px) {
    justify-items: center;
    align-items: center;
    margin-bottom: 24px;
    gap: 8px;
  }
}
